.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    min-height: 100vh;
    color: rgb(24, 24, 24);
    font-size: calc(10px + 2vmin);
  }