#toolbar-container .ql-font span[data-label='Sans Serif']::before {
  font-family: 'Sans Serif';
}

#toolbar-container .ql-font span[data-label='Inconsolata']::before {
  font-family: 'Inconsolata';
}

#toolbar-container .ql-font span[data-label='Roboto']::before {
  font-family: 'Roboto';
}

#toolbar-container .ql-font span[data-label='Mirza']::before {
  font-family: 'Mirza';
}

#toolbar-container .ql-font span[data-label='Arial']::before {
  font-family: 'Arial';
}
/* Set content font-families */

.ql-font-inconsolata {
  font-family: 'Inconsolata';
}

.ql-font-roboto {
  font-family: 'Roboto';
}

.ql-font-mirza {
  font-family: 'Mirza';
}

.ql-font-arial {
  font-family: 'Arial';
}
