@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-grids/styles/material.css';

/* test */

@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-notifications/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-navigations/styles/material.css';

p {
  margin: 0;
}

.width-600 {
  width: 600px;
}

.error-password {
  color: red !important;
}

.MuiTableCell-head {
  background: #f4f4f7;
}

.e-headercelldiv {
  font-size: 0.875rem !important;
  text-align: left !important;
  font-family: Normal !important;
  font-weight: 400 !important;
}

/* .card_selectBox > .css-b62m3t-container > .css-1nmdiq5-menu > .css-6ffcck-MenuList {
  width: 500px;
  height: auto;
  min-height: 200px;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: visible;
  overflow: scroll;
} */

.filterModal :focus-visible {
  outline: none !important;
}

.css-166jt86 :focus-visible {
  outline: none;
}

.e-headercell {
  padding: 20px !important;
  height: auto !important;
  background-color: #f4f4f7 !important;
}

.e-gridcontent td.e-rowcell {
  display: table-cell !important;
  padding: 16px !important;
  font-size: 0.875rem !important;
  text-align: left !important;
  font-family: Normal;
  font-weight: 400;
  line-height: 1.43 !important;
}

#Grid.e-control {
  overflow: hidden !important;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 20%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 1px rgb(0 0 0 / 14%) !important;
  border-radius: 4px !important;
}

.e-pager .e-currentitem {
  background: transparent !important;
  color: #000 !important;
}

.e-pager div.e-icons.e-disable {
  color: rgba(0, 0, 0, 0.26) !important;
}

#Grid_toolbarItems .e-toolbar-items,
.e-tbar-btn {
  background-color: #fff !important;
}

/* datepicker css */
.DateContainer > .MuiFormControl-root {
  width: 100%;
}

/* follow up css */
.followup > .MuiFormControl-root {
  width: 200px !important;
}

/* loader Contianer */
.loaderCantainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

/* Modal syncfusion */
#Grid_dialogEdit_wrapper {
  overflow: hidden !important;
  border-radius: 10px !important;
}

/* tm editer */

.task_Comments_Edter > .rdw-editor-wrapper > .rdw-editor-main {
  height: 120px;
  padding: 0rem 0.5rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.task_Descrpition_Edter > .rdw-editor-wrapper > .rdw-editor-main {
  padding: 0rem 0.5rem;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.task_Comments_Edter > .rdw-editor-wrapper {
  border: 1px solid #ccc;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.task_Descrpition_Edter > .rdw-editor-wrapper {
  border: 1px solid #ccc;
}

.DraftEditor-editorContainer {
  z-index: 000000 !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.rdw-editor-main::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.rdw-editor-main {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.invoice_label,
.invoice_label label {
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
  font-size: 16px;
}

.tableHeadBorderLeft {
  background: #e1e1e1;
  padding: 0px 10px 15px 10px !important;
  border-top-left-radius: 10px;
}

.tableHeadBorderRight {
  background: #e1e1e1;
  padding: 0px 10px 15px 10px !important;
  border-top-right-radius: 10px;
}

.tableHeadBorderMiddle {
  background: #e1e1e1;
  padding: 0px 10px 15px 10px !important;
}

.tableHeadBorderMiddleBody {
  padding: 0px 10px 15px 10px !important;
}

.custom_dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

.custom_dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: ' ';
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.custom_dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.custom_dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
}

.custom_dropdown .selected-value input:hover {
  border-bottom: 2px solid rgba(0, 0, 0, 0.87);
}

.custom_dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.custom_dropdown .options.open {
  display: block;
}

.custom_dropdown .option {
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}

.custom_dropdown .option.selected,
.custom_dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}

.new_dropdown_item {
  display: none;
  padding: 5px;
  box-sizing: border-box;
  background: #dcd7d7;
}

.new_dropdown_item.open {
  display: block !important;
}

.new_dropdown_item input {
  width: 80%;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  border: white;
}

.new_dropdown_item button {
  width: 20%;
  height: 39px;
  outline: none;
  border: none;
  background: black;
  color: white;
}

button:disabled {
  color: white;
  background: grey;
}

/* Invoice Modal width */
.invoice.modal-box {
  max-width: 1000px !important;
}

/* Select Box border-bottom */
.css-1iusb04-control {
  border-color: rgba(0, 0, 0, 0.42) !important;
}

/* syncfusion modal css  */
.e-float-input input {
  margin-top: 5px;
}

.e-dlg-header-content {
  padding: 0 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.e-dlg-header-content .e-dlg-closeicon-btn {
  left: 0 !important;
  top: 1rem !important;
  margin-right: 1rem !important;
}

.e-dlg-header {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  padding: 1rem 1rem !important;
  border-bottom: 0 !important;
  border-top-left-radius: calc(0.3rem - 1px) !important;
  border-top-right-radius: calc(0.3rem - 1px) !important;
  font-size: 20px !important;
  color: #000 !important;
}

.e-dlg-content {
  padding-top: 1rem !important;
}

.e-dlg-content .e-float-text.e-label-top {
  text-transform: capitalize !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 500 !important;
  line-height: 1.5rem !important;
  font-size: 16px !important;
  font-family: 'Normal' !important;
  margin-bottom: 0.5rem !important;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  color: #fff;
  background-color: #3f51b5;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  padding: 6px 16px;
  font-size: 13px;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: Normal;
  font-weight: bold;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.e-btn.e-flat:hover {
  background-color: transparent;
}

.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover,
.e-btn.e-flat.e-primary:focus {
  color: #fff;
  background-color: #303f9f;
}

.e-grid {
  min-height: 80vh;
}
/* Snippet Modal Start */
.sm-button {
  padding-block: 0.5em !important;
  font-family: 'normal' !important;
  font-size: 0.75rem !important;
  margin-bottom: 10px !important;

  background-color: #3f51b5 !important;
}
/* Snippet Modal End */
/* Invoice PDF */
.modal-body .invoice_wrapper {
  background-color: #f0eaea;
}

.invoice_wrapper {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'arial';
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
}

.in_title {
  background: grey;
  display: block;
  height: 30px;
  text-align: left;
  color: white;
  font-size: 12px;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 10px;
}

.in_title h2 {
  font-size: 18px;
  margin: 0;
}

.invoice_wrapper h3 {
  font-size: 16px;
}

.in_left {
  float: left;
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
}

.in_right {
  float: right;
  width: 50%;
  text-align: right;
  padding: 10px;
  box-sizing: border-box;
}

.in_text_right {
  display: block;
  padding: 10px;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
}

.in_text_right span {
  text-align: right;
  float: right;
}

.in_blank_box {
  height: 100px;
  clear: both;
}

.invoice_wrapper table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background: white;
}

.invoice_wrapper td,
.invoice_wrapper th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

/* Form Input error Message */
.formInputError {
  font-size: 12px;
  color: #f00;
}

/* Sidebar Custom Scroll */
.info_sidebar::-webkit-scrollbar {
  width: 5px !important;
  position: absolute;
  background: white;
  color: grey;
}

/* Track */
.info_sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.info_sidebar::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
.e-tab-text {
  color: black;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  color: #303f9f;
}

.normalBtn {
  /* background: #0069D9; */
  border-radius: 0.2rem;
  border: none;
  outline: none;
}

/* email drop down  */
.emailTemplateItem {
  font-size: 0.9rem;
  padding: 0.1 0;
}

.emailTemplateItem > .mailItem {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.26); */
  width: 100%;
  padding-inline: 5px;
}

.emailTemplateItem > .mailSub {
  border: 1px solid rgba(0, 0, 0, 0.26);
  width: 100%;
  padding-inline: 5px;
}

.emailTempItem {
  min-height: 25px;
  padding-inline: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 0.875rem;
}

/* Toggle Menu icon */
header .Cmt-toggle-menu {
  color: rgba(0, 0, 0, 0.6) !important;
}

header .Cmt-toggle-menu,
header .Cmt-appIcon {
  color: rgba(0, 0, 0, 0.6) !important;
}

/*  */

.e-headercelldiv {
  padding: 0 0 !important;
}

/* sidebar  */
.tabMain {
  padding-block: 1.25em;
  font-size: 0.9rem;
}

.tabMain > h4 {
  padding-bottom: 0.25em;
  padding-inline: 0.41em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.225);
}

.tabMain > .tabGrpItem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-collapse: collapse;
}

.tabGrpItem > div {
  min-height: 45px;
  padding-inline: 15px;
  text-transform: capitalize !important;
}

.tabGrpItem > div > span {
  color: rgba(0, 0, 0, 0.5);
}

/* sync fusion tabs */
.e-items .e-toolbar-items {
  margin-inline: auto;
}

.e-tab .e-tab-header .e-toolbar-item.e-active {
  color: #3f51b5;
}

.e-tab .e-tab-header .e-indicator {
  background: #3f51b5;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 1px;
  font-family: Normal;
  text-transform: capitalize;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text {
  color: #3f51b5;
}

.noDisplay {
  height: 0;
  display: none;
}

/* Badges Override */
.tabs-card .MuiBadge-badge {
  margin-top: 10px;
  margin-right: 8px;
}

.contact-overview .tabs-card .MuiBadge-badge {
  z-index: 0;
}

/* Sync fusion checkbox */
.e-checkbox-wrapper .e-frame.e-check {
  background-color: #3f51b5 !important;
}

.e-checkbox-wrapper:hover .e-frame.e-check {
  background-color: #3f51b5;
}

/* .gridComp .e-grid .e-columnheader:first-child{
  background-color: #f00;
} */
.gridComp .e-grid .e-gridheader tr th:first-child {
  padding-left: 23px !important;
}

/* Task management Card Details Dropdown Teams */
.teams .selected-value input {
  height: 35px;
  font-size: 12px;
  font-weight: normal;
  padding-left: 15px;
  border: 1px solid white;
  border-radius: 4px;
}

.teams .selected-value input:hover {
  border: 1px solid #c1c1c1;
}

.teams .selected-value input:focus {
  border: 2px solid #1976d2;
}

.cardDetails label {
  display: none;
}

.cardDetails fieldset {
  border: none;
}

.cardDetails input:focus + fieldset {
  border: 2px solid #1976d2;
}

.cardDetails input:hover + fieldset {
  border: 1px solid #c1c1c1 !important;
}
.cardDetails {
  width: 60vw;
}

.cardDetails .attributeCtn label {
  display: inherit;
  background: white;
}
.cardDetails .attributeCtn input {
  border: 1px solid black;
  border-radius: 5px;
}
@media (max-width: 600px) {
  .cardDetails {
    width: 100vw;
  }
}
@media (max-width: 800px) {
  .cardDetails {
    width: 80vw;
  }
}
@media (max-width: 900px) {
  .cardDetails {
    width: 70vw;
  }
}
@media (max-width: 1000px) {
  .cardDetails {
    width: 60vw;
  }
}

.cardDetails .cardDetailsLabel {
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
  margin-top: 8px;
  white-space: pre-wrap;
  color: #6d6e6f;
}

.dropzone-card {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 24px;
  padding: 6px;
  min-height: 40px;
  border-radius: 4px;
  border: 2px dashed rgb(187, 187, 187);
  background-color: rgb(245, 247, 250);
}

/* fade in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    display: block;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    display: none;
  }
}

.content-horizontal__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subSpanButton {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.subSpanButton:hover {
  color: #000;
}

.bgRow {
  background-color: rgba(0, 0, 0, 0.125);
}

.borderCellWarning {
  /* border: 1px solid red !important; */
  color: red !important;
}

.ff {
  font-family: 'Normal';
}

.import-label {
  font-size: 14px;
  font-weight: normal;
}

/* select dropdown for project */
.select-drop > .css-b62m3t-container {
  width: 300px;
  margin-top: 10px;
}
.select-drop > .css-b62m3t-container > .css-1nmdiq5-menu > .css-1n6sfyn-MenuList {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* Planning Task Drawer details due date */
.task-duedate-drawer {
  display: flex;
  padding: 0;
  /* margin-top: -10px; */
  margin-left: -22px;
  cursor: pointer;
}

.labelCantainer {
  border: 1px solid #ccc;
  width: 51% !important;
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (max-width: 1200px) {
  .labelCantainer {
    width: 100%;
  }
}

.task-duedate-drawer input {
  padding-left: 0;
}

.task-duedate-drawer input:hover + fieldset,
.task-duedate-drawer input:focus + fieldset {
  border: none !important;
}

.read-or-hide {
  color: rgb(192, 192, 192);
  cursor: pointer;
}

/* START: Light Box Gallery */
.ReactModalPortal > div {
  z-index: 99999999 !important;
}

.lightbox-img {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid rgb(234, 234, 234);
  margin-bottom: 8px;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

/* END: Light Box Gallery */

/* START: Planning Page */
.avatar-selected {
  background-color: #1b8fc0 !important;
  cursor: pointer;
}
.avatar-none {
  cursor: pointer;
}
.task-list-duedate > div {
  visibility: hidden;
  width: 0;
}
.customAvatar {
  background: #bdbdbd;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 12px;
  color: white;
  cursor: pointer !important;
}
.taskManagement .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  overflow: visible !important;
  width: 100%;
  display: flex;
  justify-content: center;
}
.taskManagement .articleCard {
  background-color: #fff;
  padding: 10px;
  margin: 5px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  /* width: 240px; */
  width: 100%;
  position: relative;
}

.tm-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.tm-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.tm-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.tm-container:hover input ~ .tm-checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.tm-container input:checked ~ .tm-checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.tm-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.tm-container input:checked ~ .tm-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.tm-container .tm-checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.taskManagement .smooth-dnd-container.vertical {
  height: 100vh;
  width: inherit;
}
.taskManagement .smooth-dnd-container.horizontal .smooth-dnd-draggable-wrapper section > div:nth-child(2) {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  width: 100%;
}
.taskManagement .smooth-dnd-container.vertical::-webkit-scrollbar {
  /* width: 5px;
  height: 8px; */
}
.taskManagement .smooth-dnd-container.vertical {
  overflow-y: auto;
  overflow-x: hidden;
}
.tmNewCardForm {
  overflow: visible !important;
}

.gg-more-vertical-alt {
  transform: scale(var(--ggs, 1));
}
.gg-more-vertical-alt,
.gg-more-vertical-alt::after,
.gg-more-vertical-alt::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 100%;
}
.gg-more-vertical-alt::after,
.gg-more-vertical-alt::before {
  content: '';
  position: absolute;
}
.gg-more-vertical-alt::after {
  left: 0;
  top: 6px;
}
.gg-more-vertical-alt::before {
  top: -6px;
  right: 0;
}

.tm-dropbtn {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.54);
  padding: 8px;
  /* width: 5px; */
  border: none;
}

.tm-dropdown {
  position: relative;
  display: inline-block;
}

.tm-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  font-size: 12px;
}

.tm-dropdown-content a {
  color: black;
  padding: 8px 10px;
  text-decoration: none;
  display: block;
}

.tm-dropdown-content a:hover {
  background-color: #fff;
}

.btn-noActive {
  display: none;
}
.btn-currentActive {
  display: block;
}

.tm-dropbtn {
  cursor: pointer !important;
}
/* .tm-dropdown:hover .tm-dropdown-content {
  display: block;
} */

.tm-dropdown .btn-disabled,
.tm-dropdown .btn-disabled[disabled] {
  opacity: 0.4;
  cursor: default !important;
  pointer-events: none;
}
.dropdown-s {
  /* display: none; */
}

.dropdown-s-active {
  display: block !important;
}
/* END: Planning Page */
.project-description-textarea:focus-visible {
  outline: none;
}
.css-1160xiw-MuiPaper-root-MuiDrawer-paper {
  width: 60%;
}
.project-add {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin-left: -18px; */
}
.project-span {
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: -10px;
  justify-content: flex-start;
  /* color: white; */
}
.select-drop > .css-1mab7ub-SelectContainer {
  position: absolute;
  z-index: 9999;
}

.select-drop > .css-1v5z18m {
  position: absolute;
  z-index: 9999;
}
.drop-up {
  top: auto;
  bottom: 100%;
}
.hideEl {
  display: none;
}

.mention *:first-child::before {
  content: '';
}

.mention *:first-child::after {
  content: '';
}

.salesRep {
  position: relative;
}
.commentSection {
}
.commentSection img {
  /* width: 500px; */
  max-width: 526px;
  height: auto;
}

.avatarCommentSection {
  border: 1px solid rgba(0, 0, 0, 0.175);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatarCommentSection img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.avatarSmall {
  width: 30px;
  height: 30px;
}

.iconContainer {
  color: #444;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cardContainerFile {
  overflow: scroll;
}

.deleteModalButon {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin-top: 1rem;
}

.attachmentConatiner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
}

/* .css-1eyfj3f-MenuList {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}
.css-1eyfj3f-MenuList::-webkit-scrollbar {
  display: none; 
} */
